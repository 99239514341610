import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllCountries,
  getAllArchitect,
  getSubscriptionStatus,
} from "../service/MainService";
import { getProjectById, getProfessionalById } from "../service/MainService";

export const AppContext = createContext({
  profileType: "",
  setProfileType: () => {},
  handleLogout: () => {},
  setSelectedCountry: () => {},
  selectedCountry: "",
  setdataStore: () => {},
  dataStore: [],
  collabStore: [],
  setCollabStore: () => {},
  scamStore: [],
  setScamStore: () => {},
});

export const AppContextProvider = ({ children }) => {
  const [profileType, setProfileType] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [dataStore, setdataStore] = useState([]);
  const [collabStore, setCollabStore] = useState([]);
  const [scamStore, setScamStore] = useState([]);
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [architect, setArchitect] = useState([]);
  const [emailVerification, setemailVerification] = useState(false);
  const [plan, setPlan] = useState({});
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [userDetail, setUserDetail] = useState({});
  const [magazines, setMagazines] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [userProducts, setUserProducts] = useState([]);
  const [userProjects, setUserProjects] = useState([]);

  const [successId, setSuccessId] = useState(null);

  const [status, setStatus] = useState({});

  const [profilePicture, setProfilePicture] = useState(localStorage.getItem("profilePicture"));

  useEffect(() => {
    const storedProfilePicture = localStorage.getItem("profilePicture");
    if (storedProfilePicture) {
      setProfilePicture(storedProfilePicture);
    }
  }, []);

  const updateProfilePicture = (newPicture) => {
    localStorage.setItem("profilePicture", newPicture);
    setProfilePicture(newPicture);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const architectList = await getAllArchitect();
        setArchitect(architectList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const CountryList = await getAllCountries();
        setCountry(CountryList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };
  const navigateToProjectDetail = async (projectId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const projectDetails = await getProjectById(projectId, token);
      fetchStatus(); // Call fetchStatus before navigating
  
      navigate(`/project/${projectId}`, {
        state: { projectDetails },
      });
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };
  
  const navigateToProfessionalDetail = async (userId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userDetails = await getProfessionalById(userId, token);
      navigate(`/professionals/${userId}`, {
        state: { userDetails },
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const navigateToProductUserDetail = async (userId) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userDetails = await getProfessionalById(userId, token);
      navigate(`/product-profile/${userId}`, {
        state: { userDetails },
      });
    } catch (error) {
      console.error("Error fetching project details:", error);
    }
  };



  const fetchStatus = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userId = localStorage.getItem("userId");
      const subscriptionStatus = await getSubscriptionStatus(userId, token);
      setStatus(subscriptionStatus);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        profileType,
        setProfileType,
        handleLogout,
        setSelectedCountry,
        selectedCountry,
        setdataStore,
        dataStore,
        collabStore,
        setCollabStore,
        scamStore,
        setScamStore,
        country,
        architect,
        emailVerification,
        setemailVerification,
        plans,
        setPlans,
        plan,
        setPlan,
        billingCycle,
        setBillingCycle,
        setUserDetail,
        userDetail,
        navigateToProjectDetail,
        navigateToProfessionalDetail,
        navigateToProductUserDetail,
        setMagazines,
        magazines,
        userProducts,
        setUserProducts,
        userProjects,
        setUserProjects,
        currentPage,
        setCurrentPage,
        successId,
        setSuccessId,
        fetchStatus,
        status,
        profilePicture,
       updateProfilePicture
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
